.ant-input-select {
    input[type="text"] {
        height: 44px;
        // border-radius: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
    }
    .ant-select-selector {
        background-color: #f0efef !important;
        color: $black;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

.ant-btn-primary {
    background-color: $primary;
    border-color: $primary-border;
    border-radius: 0.25rem;
    &:hover,
    &:focus {
        background-color: $primary-border;
        border-color: $primary-border;
    }
}

.ant-btn-default {
    border-color: $default;
    border-radius: 0.25rem;
    &:hover,
    &:focus {
        border-color: $primary;
        color: $primary;
    }
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: $danger;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.ant-form-item {
    margin-bottom: 1rem !important;
}

.ant-form label {
    font-size: 1rem;
    color: #5c5c5c;
}

.ant_switch_button_sm {
    .btn {
        font-size: 1rem !important;
        line-height: 1.25 !important;
        padding: 0 0.4rem !important;
        border-radius: 0 !important;
    }
    .switch-handle {
        background-color: $white;
    }
    &.phsio_advised {
        .btn-success {
            background-color: #666 !important;
            border-color: #666 !important;
        }
    }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.25rem;
}

.ant-select {
    .ant-select-arrow {
        font-size: 16px;
        top: 50%;
    }
    &.ant-select-open {
        .ant-select-arrow {
            font-size: 24px;
            top: 35%;
            right: 20px;
        }
    }
    &.ant-select.ant-select-disabled {
        .ant-select-selector {
            background-color: #f5f5f5;
        }
    }
}

.emrfrm .form-group.hasdata .ant-select.ant-select-disabled~label {
    background: -moz-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
    background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
    background: -o-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
    background: -ms-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
}

.emrfrm .form-label-group {
    .ant-select:not(:last-child) .ant-select-selector {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .ant-select~.input-group-addon {
        border-radius: 0 4px 4px 0;
    }
}

.react-datepicker-popper {
    z-index: 99999;
}

.ant-modal-footer {
    padding: 1.5rem 1rem;
}

.ant-modal {
    color: #5c5c5c;
}

.ant-btn.btn-primary:disabled {
    color: $white;
}

.ant-btn.btn-outline-secondary {
    font-size: 0.875rem;
    border-radius: 0.25rem;
    &:hover,
    &:focus,
    &:active {
        background-color: transparent !important;
        border-color: $primary !important;
        color: $primary !important;
        box-shadow: none !important;
    }
}

.fixed-cta .ant-btn {
    height: 42px;
}

.ant-btn.btn-outline-primary:not(:disabled) {
    &:hover {
        color: $primary;
    }
}

.emrfrm {
    .ant-btn {
        padding: 0.25rem 1rem;
        height: auto;
        border-radius: 5px;
        font-size: 1rem;
        line-height: 1.5;
    }
    .ant-input {
        font-size: 1rem;
        border-radius: 0.25rem;
        height: calc(2.6rem + 2px);
        position: relative;
        box-shadow: none !important;
        &:focus,
        &:hover {
            border-color: $primary;
            &~.ant-input-group-addon {
                border-color: $primary;
            }
        }
    }
    .border-left-radius-0 {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .border-right-radius-0 {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .ant-input-group-addon {
        border-radius: 0.25rem;
        font-size: 1rem;
        background-color: #eff0f0;
    }
    .ant-input-group>.ant-input:last-child,
    .ant-input-group-addon:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-form-item-has-error .ant-input {
        background: transparent !important;
    }
    .initailLabel {
        .ant-form-item {
            .ant-form-item-label {
                label {
                    text-transform: initial !important;
                }
            }
        }
    }
    .antInputItem,
    .antSelectItem {
        .ant-form-item {
            position: relative;
            .ant-form-item-label {
                position: absolute;
                transition: all 0.1s ease-in-out;
                top: 10px;
                left: 10px;
                outline: 0;
                z-index: 99;
                label {
                    text-transform: capitalize;
                }
            }
        }
        &.hasdata {
            .ant-form-item-label {
                top: -12px;
                display: inline-block;
                padding: 0 3px;
                left: 10px;
                background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $white 50%, $white 100%);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $white 50%, $white 100%);
                label {
                    font-size: 13px;
                    color: $primary;
                }
            }
            &.field_disabled {
                .ant-input-disabled {
                    color: #888;
                }
                .ant-form-item-label {
                    background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #f5f5f5 50%, #f5f5f5 100%);
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #f5f5f5 50%, #f5f5f5 100%);
                }
            }
        }
        &.multi-col:not(.customcssvitalform) {
            .ant-form-item-control {
                z-index: 991;
                .multi-input-col-inner {
                    .ant-input-wrapper div.ant-input {
                        padding-left: 0;
                        background: transparent;
                        position: relative;
                        &:after {
                            content: "/";
                            display: none;
                            position: absolute;
                            left: 40%;
                            top: 5px;
                            font-size: 22px;
                            color: #aaa;
                            font-weight: 300;
                        }
                        .ant-input {
                            width: 50%;
                            position: relative;
                            background: transparent;
                            top: -4px;
                            border: none;
                        }
                        &.target_height {
                            &::after {
                                content: '—';
                                left: 38%;
                            }
                        }
                    }
                    .ant-row {
                        .ant-input-group .ant-input {
                            width: 50%;
                        }
                    }
                }
            }
            &.hasdata {
                .ant-form-item-label {
                    z-index: 992;
                }
                .multi-input-col-inner .ant-input-wrapper div.ant-input {
                    &:after {
                        display: block;
                    }
                }
            }
        }
        &.customcssvitalform {
            .ant-form-item-control {
                z-index: 991;
                .multi-input-col-inner {
                    .ant-input-wrapper div.ant-input {
                        padding-left: 0;
                        background: transparent;
                        position: relative;
                        padding-right: 0;
                        .ant-input {
                            width: 100%;
                            position: relative;
                            background: transparent;
                            top: -4px;
                            border: none;
                        }
                        .ant-input-disabled {
                            background-color: #f5f5f5;
                            cursor: not-allowed;
                            opacity: 1;
                            top: -4px;
                            height: calc(2.6rem);
                        }
                        &.target_height {
                            &::after {
                                content: '—';
                                left: 38%;
                            }
                        }
                    }
                    .ant-row {
                        .ant-input-group .ant-input {
                            width: 50%;
                        }
                    }
                }
            }
            &.disabled {
                .ant-form-item-label {
                    z-index: 999 !important;
                }
            }
            &.hasdata {
                .ant-form-item-label {
                    z-index: 993;
                }
                .multi-input-col-inner .ant-input-wrapper div.ant-input {
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
    .ant-select-multiple .ant-select-selection-search {
        overflow: hidden;
    }
}

.ant-notification-notice-close {
    top: 5px;
    right: 10px;
}

.ant-modal-content {
    border-radius: 0.5rem;
    .ant-modal-header {
        border-radius: 0.5rem 0.5rem 0 0;
        .ant-modal-title {
            font-size: 1.125rem;
            color: #5c5c5c;
        }
    }
}

.add_component_modal {
    .mt-4 {
        margin-top: 0px !important;
    }
    .text-heading {
        display: none !important;
    }
    #create-medicine-details .my-4 {
        margin-top: 0px !important;
    }
}

#template-select_list+.rc-virtual-list {
    .ant-select-item-option {
        height: 56px;
    }
}

.ant-select {
    &.ant-select-single {
        .template-S1 {
            display: inline-flex;
            &::after {
                content: "|";
                padding: 0px 4px;
            }
        }
        .template-S2 {
            display: inline-flex;
        }
        .template-S3 {
            position: relative;
            top: -2px;
        }
    }
}

.add_component_modal {
    .mt-4 {
        margin-top: 0px !important;
    }
    .text-heading {
        display: none !important;
    }
    #create-medicine-details .my-4 {
        margin-top: 0px !important;
    }
    .modal-medicine {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.all_vital .ant-modal-content {
    .ant-modal-header {
        margin-bottom: 25px;
    }
    .ant-modal-body {
        padding-top: 0px;
    }
}

.single_vital .ant-modal-content .ant-modal-body {
    padding-top: 10px;
    position: relative;
    .wrap_chart_filter {
        z-index: 9;
        top: 50px;
    }
    .chartCanvas {
        padding-top: 50px;
    }
}

.ant-modal-wrap {
    background: rgba(0, 0, 0, 0.8);
    // z-index: 7777 !important;
    z-index: 7777;
}

.ant-select-dropdown{
    z-index:7788 !important
}

.ant-picker-dropdown{
    z-index:7788 !important
}
.ant-tooltip {
    z-index: 7800 !important;
}

.ant-modal-wrap.pubnub_modal {
    z-index: 1050;
}

.medicine_star {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 70px;
        position: relative;
        .desc {
            max-width: calc(100% - 70px);
        }
        .float-right.text-primary {
            position: absolute;
            top: 10px;
            right: 20px;
        }
    }
}

.custom_drug_select {
    .ant-select-item-option {
        min-height: 40px;
        height: auto;
        .ant-select-item-option-content {
            width: 100%;
            position: relative;
            white-space: unset;
            .desc {
                max-width: calc(100% - 50px);
                display: block;
            }
            .float-right.text-primary {
                width: 40px;
                position: absolute;
                top: 2px;
                right: -20px;
            }
        }
        &.ant-select-item-option-active,
        &.ant-select-item-option-selected {
            .ant-select-item-option-content .float-right.text-primary {
                .icon_star:after {
                    @include star_icon($fill_white);
                }
            }
        }
    }
}

.ant-select-selection-overflow {
    padding-top: 5px;
}

.ant-checkbox-wrapper {
    .ant-checkbox-inner {
        width: 1.125rem;
        height: 1.125rem;
        border-color: $color_gray_border;
        &:hover {
            border-color: $primary;
        }
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-color: $primary;
            background-color: transparent;
            &::after {
                border-color: $primary;
                width: 5.5px;
                height: 12px;
                top: 42%;
            }
        }
        &:hover {
            &::after {
                border-color: $primary;
            }
        }
    }
    &:hover {
        .ant-checkbox-inner {
            border-color: $primary;
        }
        .ant-checkbox::after {
            border-color: $primary;
        }
    }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: $color_gray_border !important;
}

.antInputItem.multi-col {
    .multi-input-col-inner .ant-input-wrapper {
        .target_height {
            .ant-form-item-label {
                position: relative;
                z-index: 999;
                top: 5px;
                left: 0px;
                transition: all 0.1s ease-in-out;
            }
            .ant-row {
                width: 50%;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 1;
                &.second_col {
                    left: 50%;
                    &:before {
                        content: '—';
                        display: none;
                        position: absolute;
                        left: -16px;
                        top: 9px;
                    }
                }
                .ant-input {
                    border: none;
                    height: calc(2.2rem + 2px)
                }
            }
        }
    }
    &.hasdata {
        .multi-input-col-inner .ant-input-wrapper {
            .target_height {
                .ant-form-item-label {
                    top: -20px;
                }
                .ant-row {
                    &.second_col {
                        &:before {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.ant-pagination-options {
    display: none;
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: $primary;
    box-shadow: none;
    border-radius: 0.25rem;
}

.micwrap.ant-mic-wrap {
    .mic {
        right: 4px;
        bottom: 8px;
        &.r-0 {
            right: 0px;
        }
    }
}
.ant-notification {
    z-index: 7900 !important;
}